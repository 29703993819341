import {HTTP} from "@/axios.js"
import {LMS} from "@/axios.js";
HTTP.defaults.timeout = 600000


export default {
    async getEventListAll(){
      const response = await HTTP.get(`api/event/eventall`)
      .then(res => {
        if (res.data) {
          // console.log("getCourseByOwner(API Service):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async getEventListByOwner(email){
        const response = await HTTP.get(`api/event/info?owner=${email}`)
        .then(res => {
          if (res.data) {
            // console.log("getCourseByOwner(API Service):",res.data);
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async getStudentStatus(eventId){
        const response = await HTTP.get(`api/event/list/status?eventid=${eventId}`)
        .then(res => {
          if (res.data) {
            console.log("studentStatus(API)",res.data)
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async getEventById(eventId){
        const response = await HTTP.get(`api/event/byid?eventid=${eventId}`)
        .then(res => {
          if (res.data) {
            console.log("getEventById(API)",res.data)
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async getStudentReport(eventId){
        const response = await HTTP.get(`api/event/leave/report?eventid=${eventId}`)
        .then(res => {
          if (res.data) {
            console.log("studentReport(API):",res.data);
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async getStudentReportEachUser(eventId, email){
      const response = await HTTP.get(`api/event/leave/user?eventid=${eventId}&email=${email}`)
      .then(res => {
        if (res.data) {
          console.log("getStudentReportEachUser(API):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async getSection(courseCode, courseSection, term){
      const response = await HTTP.get(`api/reg/detailofcourse?courseid=${courseCode}&semester=${term}`)
      .then(res => {
        if (res.data) {
          console.log("getSection(API):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async getQuiz(examId){
      const response = await HTTP.get(`api/lms/quizzes/list?courseid=${examId}`)
      .then(res => {
        if (res.data) {
          console.log("getQuiz(API):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async getAssignment(examId){
      const response = await HTTP.get(`api/lms/assignment/list?courseid=${examId}`)
      .then(res => {
        if (res.data) {
          console.log("getAssignment(API):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async createEvent(data){
      console.log(data)
      const response = await HTTP.post(`api/event/create`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
            console.log("createEvent(API):",res.data)
            return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async deleteEvent(eventId){
      console.log(eventId)
      const response = await HTTP.delete(`api/delete/event?eventid=${eventId}`)
        .then(res => {
            if (res.data) {
              console.log("deleteEvent(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async getEventAvailableById(eventId){
      const response = await HTTP.get(`api/event/available/byeventid?eventid=${eventId}`)
      .then(res => {
        if (res.data) {
          console.log("getEventAvailableById(API):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async createEventAvailable(eventId){
      console.log(eventId)
      const response = await HTTP.post(`api/event/available/create`,
      {
        "eventid":eventId
      }
      )
        .then(res => {
            if (res.data) {
              console.log("createEventAvailable(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async deleteEventAvailable(eventId){
      console.log(eventId)
      const response = await HTTP.delete(`api/event/available/delete?eventid=${eventId}`
      )
        .then(res => {
            if (res.data) {
              console.log("deleteEventAvailable(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async getStudentPhoto(studentId){
      const response = await HTTP.get(`api/mis/photo/student?studentid=${studentId}`, {
        responseType: 'arraybuffer'
      })
      .then(res => {
        res.data = new Buffer(res.data, 'binary').toString('base64')
        if (res.data) {
          console.log("getStudenPhoto(API):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async createUserManual(data){
      console.log(data)
      const response = await HTTP.post(`api/event/usermanually/create`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
            console.log("createUserManual(API):",res.data)
            return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async checkGrant(email){
      const response = await LMS.get(`api/CheckGrantAccess?email=${email}`)
      .then(res => {
        if (res.data) {
          console.log("checkGrant(API):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },
    async creatSandbox(data){
      console.log(data)
      const response = await LMS.post(`api/CreateSandbox`,data, {timeout: 600000})
        .then(res => {
          if (res.data) {
            console.log("creatSandbox(API):",res.data)
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async checkSandboxCreated(lms_type, email){
      const response = await LMS.get(`api/CheckSandboxCreated?email=${email}&lms_type=${lms_type}`)
      .then(res => {
        if (res.data) {
          console.log("checkSandboxCreated(API):",res.data);
          return res.data
        }
      })
      .catch(e => {
        console.log(e);
      });
      return response
    },

}